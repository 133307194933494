"use strict";

function $(sel, el) {
  if (!el) {
    el = document;
  }
  return el.querySelector(sel);
}

var pocketIframeUrl;
var pocketUrl;
var pocketProvider;
var pocketRedirect = window.location.href;
var pocketNonce = parseInt(Math.random().toString().slice(2), 10).toString(16);
var pong;
var onToken;
var callbacks = {};
var freshToken;
var freshJws;
var freshUser;
var pongPromise = (function () {
  var _resolve;
  var p = new Promise(function (resolve) {
    _resolve = resolve;
  });
  p.resolve = _resolve;
  return p;
})();

/*
function addCSS(css) {
  var head = document.head || document.getElementsByTagName('head')[0];
  var style = document.createElement('style');
  style.type = 'text/css';
  if (style.styleSheet) {
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
  head.appendChild(style);
}
*/

function w64Tob64(w64) {
  try {
    return atob(
      w64
        .replace(/-/g, "+")
        .replace(/_/g, "/")
        .padEnd(w64.length + ((4 - (w64.length % 4)) % 4), "=")
    );
  } catch (e) {
    throw e;
  }
}

function decodeJwt(jwt) {
  var arr = (jwt || "").split(".");
  var jws = {
    protected: arr[0],
    header: JSON.parse(w64Tob64(arr[0])),
    payload: arr[1],
    claims: JSON.parse(w64Tob64(arr[1])),
    signature: arr[2],
  };
  return jws;
}

function isFresh(jws) {
  if (!jws || !jws.claims || !jws.claims.exp) {
    return false;
  }

  var exp = parseInt(jws.claims.exp, 10) || 0;
  exp *= 1000;
  return exp - Date.now() > 5 * 60 * 1000;
}

function isUsable(jws) {
  if (!jws || !jws.claims || !jws.claims.exp) {
    return false;
  }

  var now = Date.now();
  var exp = parseInt(jws.claims.exp, 10) || 0;
  exp *= 1000;
  return exp - now > 30 * 1000;
}

function showSignin(ev) {
  if (ev) {
    if ("function" === typeof ev.stopPropagation) {
      ev.stopPropagation();
      ev.preventDefault();
    }
  }
  $(".pocket iframe").style.display = "block";
}

function pingMe(msg) {
  console.warn(location.host, "[pocketid.incoming] received msg");
  console.log(msg);
  var msgId;
  switch (msg.type) {
    case "init":
      if (!pong) {
        /*
        pong = function _my_pong(msg) {
          event.source.postMessage(JSON.stringify(msg), pocketProvider);
        }
        */
        console.info("[pocketid] init via postMessage");
        pong = true;
        pongPromise.resolve(null);
      }
      break;
    case "size":
      // ignore for full screen
      //$('.pocket iframe').style.width = msg.width;
      //$('.pocket iframe').style.height = msg.height;
      break;
    case "token":
      $(".pocket iframe").style.display = "none";
      freshToken = msg.token;
      freshJws = decodeJwt(msg.token);
      freshUser = msg;
      if ("function" === typeof onToken) {
        onToken(msg.token);
      }
      msg.value = Object.assign({}, msg);
      break;
    case "callback":
      // { type = 'callback', id, value, error }
      msgId = msg.id;
      if (!callbacks[msgId]) {
        console.error(location.host, "[pocketid] received message with no corresponding request:");
        console.log(msg);
        return;
      }

      try {
        // should be a Promise#resolve
        var idToken = msg.value.id_token || msg.value.token || msg.value.access_token;
        if (msg && msg.error) {
          msg = Promise.reject(Object.assign(new Error(msg.error), msg));
        } else if (msg && idToken) {
          // TODO check message value type:
          freshUser = msg.value;
          freshToken = idToken;
          freshJws = decodeJwt(idToken);
        }
        callbacks[msgId](msg.value || msg);
      } catch (e) {
        console.error("[pocketid] bad ping callback:");
        console.error(e);
      }
      break;
    default:
      // TODO update domain name
      console.warn("[pocketid] unhandled message type");
      console.warn(new Error("ping stack").stack);
      console.log(msg);
  }
}

function initPocketWindow(opts) {
  if (!opts) {
    opts = {};
  }
  var init = "fns-" + pocketNonce.slice(0, 4) + "-init";

  if (!pocketUrl) {
    //pocketUrl = "https://" + new URL(document.currentScript.src).host + "/login";
    pocketUrl = new URL(document.currentScript.src).origin + "/login/";
    if ("localhost:7258" === window.location.host) {
      pocketUrl = window.location.protocol + "//" + window.location.host + "/";
    }
  }
  // won't work for app://
  pocketProvider = new URL(pocketUrl).origin;

  var params = {
    state: pocketNonce,
    // security check happens later
    redirect_uri: opts.redirect || pocketRedirect,
    origin: document.location.host,
    email: opts.email || undefined,
    tel: opts.tel || undefined,
    verify_immediately: opts.verifyImmediately || undefined,
    providers: (opts.providers || []).join(",") || undefined,
  };
  var query = Object.keys(params)
    .map(function (k) {
      var v = params[k];
      if ("undefined" === typeof v) {
        return;
      }
      return encodeURIComponent(k) + "=" + encodeURIComponent(params[k]);
    })
    .filter(Boolean)
    .join("&");
  var iframeUrl = pocketUrl + "#/?" + query;
  if (pocketIframeUrl === iframeUrl) {
    return;
  }
  pocketIframeUrl = iframeUrl;
  $(".pocket").innerHTML = `<style>
    .pocket iframe {
      background-color: #eaeaea;
    }
    </style>
    <iframe
      allowtransparency="true"
      frameborder="0"
      style="
        z-index: 2147483645;
        display: none;
        background: rgba(0, 0, 0, 0.5);
        border: 0px none transparent;
        overflow: hidden auto;
        visibility: visible;
        margin: 0px;
        padding: 0px;
        -webkit-tap-highlight-color: transparent;
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
      "
      src="${iframeUrl}">
    ></iframe>`;

  window[init] = function (_nonce, _pong, setPing) {
    console.info("[pocketid] init via iframe");
    if (pocketNonce !== _nonce) {
      console.error("invalid nonce: attacker?");
      return;
    }
    pong = _pong;
    pongPromise.resolve(null);
    setPing(pingMe);
  };
}

var Pocket = {
  init: function (opts) {
    if (!opts) {
      opts = {};
    }
    if (opts.url) {
      pocketUrl = opts.url;
      pocketProvider = new URL(opts.url).origin;
    }
    if (!opts.redirect) {
      opts.redirect = opts.redirectUri || opts.redirectUrl || pocketRedirect;
    }
    if (!opts.nonce) {
      opts.nonce = pocketNonce;
    }

    initPocketWindow(opts);
  },
  openSignin: showSignin,
  signin: async function (hints) {
    await pongPromise;
    var p = new Promise(function (resolve) {
      var rnd = Math.random().toString().slice(2);
      var msg = { api: "signin", hints: hints, callback: rnd };
      callbacks[rnd] = resolve;
      Pocket.pong(msg);
    });
    showSignin();
    //$(".pocket iframe").style.display = "block";
    return p;
  },
  logout: async function (opts) {
    // TODO hit endopint to delete cookie
    freshUser = null;
    freshJws = null;
    freshToken = null;
    return;
  },
  token: async function (opts) {
    if (!opts) {
      opts = {};
    }

    if (!isUsable(freshJws)) {
      if (!freshJws) {
        console.log("[pocketid] token does not exist");
      } else {
        console.log("[pocketid] token has expired");
      }
      return window.Pocket._token(opts);
    }

    console.log("[pocketid] token is usable");
    // TODO add unverifiable_email
    if (!isFresh(freshJws)) {
      console.log("[pocketid] token is stale");
      window.Pocket._token(opts);
    }

    return freshUser;
  },
  _token: async function (opts) {
    // TODO load non-interactive iframe
    console.log("[pocketid] attempting to get / refresh token");
    await pongPromise;
    return new Promise(function (resolve) {
      var rnd = Math.random().toString().slice(2);
      callbacks[rnd] = resolve;
      Pocket.pong({ api: "token", callback: rnd });
    });
  },
  onToken: function (_onToken) {
    onToken = _onToken;
    if (isUsable(freshJws)) {
      onToken(freshToken);
      freshToken = null;
    }
  },
  pong: function sendPong(msg) {
    var sent = false;
    var iframe = $(".pocket iframe");

    console.warn("%s [pocketid.outgoing] signin", location.host, msg);
    if ("function" === typeof pong) {
      try {
        pong(msg);
        sent = true;
      } catch (e) {
        console.error("[pocketid] failed to send pong:");
        console.error(e);
        // ignore
      }
    }
    if (!sent) {
      try {
        iframe.contentWindow.postMessage(JSON.stringify(msg), pocketProvider);
      } catch (e) {
        iframe.postMessage(JSON.stringify(msg), pocketProvider);
      }
    }
  },
};
window.Pocket = Pocket;

// TODO how best to handle this?
initPocketWindow({
  nonce: pocketNonce,
});

window.addEventListener(
  "message",
  function (event) {
    console.debug('[pocketid.consumer] (postMessage from "%s"):', event.origin);
    console.debug(event);
    if (event.origin !== pocketProvider) {
      return;
    }
    var msg = event.data;
    if ("string" === typeof msg) {
      msg = JSON.parse(msg);
    }
    pingMe(msg);
  },
  false // useCapture
);
